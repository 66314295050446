@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.Perfil-perfil{
  width: 150px !important;
  height: 150px !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#div-con-fondo{
  background-image:"./img/image1.jpeg";
}
.Imagen-Fondo {
  background-image:url("./img/descarga.jfif");
  margin-left: auto;
  background-attachment: fixed;
  background-size:35%;
  position:static;
  border-radius: 2%;
  width:468px;
  height:220px;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Nlikes_comentarios{
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.HorayFecha{
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
/*PIA CSS*/

.principal{
  align-items: left;
  display: flex;
  height: 95vh;
  width: 50vh;
  background-color: #C8D3D5;
  border-radius: 50px 0px 0px 50px;
  position: absolute;
  left: 1%;
  top: 2%;
}

.contenido-pagina{
  align-items: left;
  display: flex;
  height: 95vh;
  width: 70%;
  background-color: white;
  position: absolute;
  left: 25%;
  top: 2%;
  /* border: #282c34 solid 1px; */
}
.navbar-opciones{
  position: absolute;
  width: 100%;
  height: 520px;
  /* border: #03802C solid 2px; */
  border-radius: 50px 0px 0px 0px;

}
.secundario{
  position: absolute;
  margin: 5vh;
  top: 66%;
}

body{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  /* background-image: url("./img/fondo1.jpg"); */
}

#boton-salirperfil{
  display: flex;
  position:relative;
}
#boton-iniciosesion{
  background-color: #0CCA4A;
  border: none;
  height: 7vh;
  width: 40vh;
  font-family: 'Montserrat', sans-serif;
  border-radius: 40px;
  font-size: medium;
  transition: 0.5s;
  font-weight:600;
}

#boton-iniciosesion:hover{
  background-color: white;
  border: #0CCA4A solid 2px;
  color: #0CCA4A;
  transition: 0.5s;
}
#boton-follow{
  background-color: #0CCA4A;
  border: none;
  height: 6vh;
  width: 40vh;
  font-family: 'Montserrat', sans-serif;
  border-radius: 40px;
  font-size: medium;
  transition: 0.5s;
  font-weight:600;
  position: relative;
  left: 145px;
  top: -66px;
}

#boton-follow:hover{
  background-color: white;
  border: #0CCA4A solid 2px;
  color: #0CCA4A;
  transition: 0.5s;
}

#boton-perfil{
  background-color: #03802C;
  border: none;
  height: 7vh;
  width: 40vh;
  font-family: 'Montserrat', sans-serif;
  border-radius: 40px;
  font-size: medium;
  transition: 0.5s;
  margin-top: -10px;
  font-weight:600;
}
#boton-perfil:hover{
  background-color: white;
  border: #03802C solid 2px;
  color: #03802C;
  transition: 0.5s;
}
#boton-registrarse{
  background-color: #03802C;
  border: none;
  height: 7vh;
  width: 40vh;
  font-family: 'Montserrat', sans-serif;
  border-radius: 40px;
  font-size: medium;
  transition: 0.5s;
  margin-top: -10px;
  font-weight:600;
}

#boton-registrarse:hover{
  background-color: white;
  border: #03802C solid 2px;
  color: #03802C;
  transition: 0.5s;
}

#logo-tt{
  margin-left: 10%;
  margin-top: 10%;
  position: relative;
}

#barra-buscar{
  position: relative;
  top: 30px;
  left: 10%;
  padding: 10px;
  padding-left: 30px;
  width: 81%;
  border: none;
  border-radius: 20px;
  font-family: 'Montserrat';
  font-weight:500;
}

#barra-buscar:focus {
  outline-width: 0;
}

#home-navbar{
  position: absolute;
  left: 10%;
  top: 35%;
  width: fit-content;
  padding: 15px;
  transition: 0.5s;
  border-radius: 30px ;
  border: none;
}
#home-navbar:hover{
  border: none;
  transform: scale(1.03);
  transition: 0.5s;
  border-radius: 30px;
  background-color: white;
  cursor: pointer;
  width: fit-content;
}

#notifications-navbar{
  position: absolute;
  left: 10%;
  top: 61%;
  width: fit-content;
  padding: 15px;
  transition: 0.5s;
  border-radius: 30px ;
  border: none;
}
#notifications-navbar:hover{
  border: none;
  transform: scale(1.03);
  transition: 0.5s;
  border-radius: 30px;
  background-color: white;
  cursor: pointer;
  width: fit-content;
}

#inicio-navbar{
  position: absolute;
  left: 10%;
  top: 48%;
  width: fit-content;
  padding: 15px;
  transition: 0.5s;
  border-radius: 30px ;
  border: none;
}
#inicio-navbar:hover{
  border: none;
  transform: scale(1.03);
  transition: 0.5s;
  border-radius: 30px;
  background-color: white;
  cursor: pointer;
  width: fit-content;
}

.navbar-items{
  position: relative;
  font-family: 'Montserrat';
  font-weight:600;
  margin-left: 10px;
  font-size: 20px;
  top: 1px;
  color: #738386;
}

#btn-iniciosesion2{
  padding: 10px;
  border-radius: 50px;
  font-family: 'Montserrat';
  font-weight:600;
  background-color: #0CCA4A;
  border: none;
}
#btn-iniciosesion2:hover{
  background-color: #03802C;
  transition: 0.5s;
}

.titulo-modal{
  font-family: 'Montserrat';
  font-weight:600;
  color: #03802C;
  position: relative;
  top: 2px;
}

.titulo-modal-perfil{
  font-family: 'Montserrat';
  font-weight:900;
  color: #ffffff;
  position:relative;
  top: 63px;
  left: 145px;
}
.Subtitulo-modal{
  font-family: 'Montserrat';
  font-weight:500;
  color: #ffffff;
  position:relative;
  top: 88px;
  left: 80px;
  margin-bottom: 40px;
}
#btn-registro{
  padding: 10px;
  border-radius: 50px;
  font-family: 'Montserrat';
  font-weight:600;
  background-color: #0CCA4A;
  border: none;
  color: white;
}
#btn-registro:hover{
  background-color: #03802C;
  transition: 0.5s;
}
#btn-publicar{
    background-color: #0CCA4A;
    border: none;
    height: 7vh;
    width: 100% !important;
    font-family: 'Montserrat', sans-serif;
    border-radius: 40px;
    font-size: medium;
    transition: 0.5s;
    font-weight:600;
    margin-top: 2vh;
}
#btn-subir{
  background-color: #0CCA4A;
  border: none;
  height: 7vh;
  width: 30% !important;
  font-family: 'Montserrat', sans-serif;
  border-radius: 40px;
  font-size: small;
  transition: 0.5s;
  font-weight:600;
  position: relative;
  left: 50%;
 
}
#btn-subir:hover{
  transform: scale(1.05);
}

.pregunta-shortalk{
  font-family: 'Montserrat';
  font-weight: 600;
  color: #282c34;
  margin-left: 7%;
  font-size: large;

}
.shorttalks{
  /* border: #282c34 solid 1px; */
  position: absolute;
  margin-top: 4%;
  left: 5%;
  width: 90%;
  height: 17vh;
  overflow-x: scroll;
  overflow-y: hidden;
  display:inline-flex;
  align-items: center;
  align-content: center;

}
.shorttalks::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.user{
  position: absolute;
  /* border: #c39500 solid 1px !important; */
  width: 10% !important;
  height: 100% !important;
  align-items: center !important;

}
.friends-short{
  position: relative;
  /* border: #0CCA4A solid 1px; */
  width: 10%;
  height: 100%;
  align-items: center;
  margin-left: 3.5vh;
  cursor: pointer;
}
.fotoperfil{
  position: relative;
  width: 12.5vh;
  height: 12.5vh;
  background-size: cover;
  border-radius: 100%;
  background-color: #738386;
}

.isShortUpload{
  border: #0CCA4A solid 5px;
}

.nombre-short{
  font-family: 'Montserrat';
  font-weight: 300;
  position: relative;
  margin-left: 20%;
}
.fotoperfil-friend{
  position: relative;
  width: 12.5vh;
  height: 12.5vh;
  background-size: cover;
  border-radius: 100%;
    background-image: url("./img/pp1.jpg");
}
.posts-tt{
  position: absolute;
  /* border: #282c34 solid 1px; */
  width: 80%;
  height: 70%;
  margin-top: 18%;
  margin-left: 10%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.posts-tt::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.post-user{
  background-color: #F5F3F3;
  height: fit-content;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 20px;
  display: inline-flex;
  padding-bottom: 20px;
}
.post-content > img{
  border-radius: 30px;
}
.post-user-perfil{
  background-color: #F5F3F3;
  height: 120px;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 5px;
  display: inline-flex;
  position: relative;
}
.fotoperfil-post{
  position: relative;
  width: 100px;
  height: 100px;
  background-size: cover;
  border-radius: 100%;
  background-image: url("./img/pp1.jpg");
}
.fotoarea{
  /* border: #282c34 solid 1px; */
  height: 100%;
  width: fit-content;
  padding: 20px;
}
.text-postarea{
  /* border: #282c34 solid 1px; */
  height: 100%;
  width: 90%;
}
.text-postarea-perfil{
  /* border: #282c34 solid 1px; */
  height: 0%;
  width: 90%;
}
.post-content{
  position: relative;
  margin-left: 3%;
  margin-top: 3%;
  font-family: 'Montserrat';

}
.post-content > button{
  position: absolute;
  right: 0;
  
}
.post-content-perfil{
  position: relative;
  margin-left: 3%;
  margin-top: 3%;
  font-family: 'Montserrat';

}
.post-content-perfil > button{
  position: absolute;
  right: 0;
  
}
.boton-post{
  border: none;
  background-color: transparent;
  transition: 0.5s;
}
.boton-post:hover{
  transform: scale(1.05);
  transition: 0.5s;
}
#likebtn{
  margin-right: 55px;
}


/*Explore*/

.exploreCont {
  position: absolute;
  margin-top: 4%;
  left: 10%;
  width: 90%;
  height: 17vh;
  /*display:inline-flex;*/
  align-items: center;
  align-content: center;
}

#boton-explore {
  background-color: #b8b8b8;
  border: none;
  height: 7vh;
  width: 60vh;
  font-family: 'Montserrat', sans-serif;
  font-size: medium;
  transition: 0.5s;
  font-weight: 600;
}

#boton-explore-use {
  background-color: #555555;
  border: none;
  height: 7vh;
  width: 60vh;
  font-family: 'Montserrat', sans-serif;
  font-size: medium;
  transition: 0.5s;
  font-weight: 600;
}

#txt-num-likes {
  color: #0CCA4A;
}

.cont-hash {
  position: relative;
  width: 250px;
  height: 150px;
  background-size: cover;
  border-radius: 20px;
  background-color: #0CCA4A;

}

.hash-content {
  position: relative;
  margin-left: 7%;
  margin-top: 3%;
  font-family: 'Montserrat';
}

#txt-hash {
  color: #F5F3F3;
  position: relative;
  margin-left: 10%;
  margin-top: 25%;
  font-family: 'Montserrat';
  font-size: 26px;
}

#txt-categorias {
  color: #555555;
  position: relative;
  margin-left: 70%;
  font-family: 'Montserrat';
  font-size: 26px;
  top: -40px;
}

.dropdown{
  height: 0%;
}

a:-webkit-any-link {
  text-decoration: none;
}

.comment-user{
  background-color: #F5F3F3;
  height: 100px;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 20px;
  display: inline-flex;
}
.fotoperfil-comment{
  position: relative;
  width: 60px;
  height: 60px;
  background-size: cover;
  border-radius: 100%;
  background-image: url("./img/pp1.jpg");
}
.comment-content{
  position: relative;
  margin-left: 3%;
  margin-top: 3%;
  font-family: 'Montserrat';

}
.comment-content > button{
  position: absolute;
  right: 0;
  
}
#likeCommentbtn{
  margin-right: 25px;
}

.comment-post{
  position: absolute;
  /* border: #282c34 solid 1px; */
  width: 80%;
  margin-top: 60%;
  margin-left: 10%;
}
#btn-comment{
  padding: 10px;
  width: 20%;
  border-bottom-right-radius: 20px;
  font-family: 'Montserrat';
  font-weight:500;
  background-color: #0CCA4A;
  border: 1px solid #C8D3D5;
  color: white;
}
#txt-comment{
  padding: 10px;
  padding-left: 30px;
  width: 80%;
  border-bottom-left-radius: 20px;
  font-family: 'Montserrat';
  font-weight:500;
  border: 1px solid #C8D3D5;
}
#txt-comment:focus{
  outline: none;
}

#text-post{
  resize : none
}
#text-hash{
  border-radius: 10px;
  width: 100%;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  outline : none;
  border: 1px solid #C8D3D5;
  font-family: 'Montserrat';
  font-weight:600;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
#foto-adj{
  display: none;
}
#video-adj{
  display: none;
}
#icon-pic{
  cursor: pointer;
  transition: 0.5s ease-in-out;
  margin-right: 10px;
}
#icon-pic:hover{
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}

#icon-vid{
  cursor: pointer;
  transition: 0.5s ease-in-out;
  margin-right: 10px;
}
#icon-vid:hover{
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}
#foto-subir1 > img{
  border-radius: 15%;
  object-fit: cover;
  width: 130px;
  height: 130px;
  transition: 1.5s ease-in;

}

#foto-subir1 {
  width: 130px;
  height: 130px;
  background-color: #03802C;
  margin-top: 15px;
  opacity: 0;
  transition: 1.5s ease-in;
  border-radius: 15%;

}

#dropdowncategorias{
  padding: 10px 15px 10px 15px;
  border-radius: 20px;
  width: 30%;
  outline : none;
  border: 1px solid #C8D3D5;
  font-family: 'Montserrat';
  font-weight:500;
  position: relative;
  left: 50%;
}
#dropdowncategorias2{
  padding: 10px 15px 10px 15px;
  border-radius: 20px;
  width: 30%;
  outline : none;
  border: 1px solid #C8D3D5;
  font-family: 'Montserrat';
  font-weight:500;
  position: relative;
}
#error-login{
  font-family: 'Montserrat';
  font-weight: 600;
  color:  red !important;
  margin-left:  5px;
  margin-top: 10px;
  opacity: 0;
  transition: 0.1s;
}
#short-adj{
  opacity: 0; position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height:100%; cursor: pointer;
}
.perfil-cuadro{
  height: 300px;
  width: 460px;
}